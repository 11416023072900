<template>
  <Detail :id="id" :is-edit="true" />
</template>

<script>
import Detail from './components/Detail'

export default {
  name: 'EditContentData',
  components: { Detail },
  data() {
    return {
      tableId: '',
      id: this.$route.query.id,
      show: false
    }
  },
  created() {
    this.tableId = this.$route.params.id || this.$route.meta.code
    this.show = true
  }
}
</script>

